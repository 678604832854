import React, { useEffect } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
//import InstaLogo from "../../static/images/commonImg/instagram.svg"
import AmazonLogo from "../../static/images/commonImg/amazon-radius.svg"
import TwitterLogo from "../../static/images/commonImg/twitter-logo.svg"
import NoteLogo from "../../static/images/commonImg/note-logo.svg"
import ReactBook from "../../static/images/commonImg/reactbook.svg"
import ReactBookTS from "../../static/images/commonImg/reactbook-ts.svg"
import ReactBook2 from "../../static/images/commonImg/reactbook2.svg"
import ReactBook2TS from "../../static/images/commonImg/reactbook2-ts.svg"
import NextBook from "../../static/images/commonImg/nextbook.svg"
import GatsbyBook from "../../static/images/commonImg/gatsbybook.svg"
import JamstackBook from "../../static/images/commonImg/jamstackbook.svg"
import NodeBookIntro from "../../static/images/commonImg/nodebook-intro.svg"
import NodeBookAdvanced from "../../static/images/commonImg/nodebook-advanced.svg"
import Nextbook2a from "../../static/images/commonImg/nextbook2-1.svg"
import Nextbook2b from "../../static/images/commonImg/nextbook2-2.svg"
import NextbookFull1 from "../../static/images/commonImg/nextbook-app-fullstack-1.svg"
import NextbookFull2 from "../../static/images/commonImg/nextbook-app-fullstack-2.svg"
import Nextbook2aTS from "../../static/images/commonImg/nextbook2-1ts.svg"
import Nextbook2bTS from "../../static/images/commonImg/nextbook2-2ts.svg"
import Mernbook1 from "../../static/images/commonImg/mern-book-1.svg"
import Mernbook2 from "../../static/images/commonImg/mern-book-2.svg"
import VueBook from "../../static/images/commonImg/vuebook.svg"
import NuxtBook from "../../static/images/commonImg/nuxtbook.svg"
import JSbook from "../../static/images/commonImg/jsbook.svg"
import ProxyBook from "../../static/images/commonImg/proxyserver-book.png"
import AstroBook from "../../static/images/commonImg/astrobook.svg"
import NextServerActionsBook from "../../static/images/commonImg/nextjs-server-actions.svg"
import ReactMasterBook from "../../static/images/commonImg/react-master-book.svg"
import TSmasterBook from "../../static/images/commonImg/typescript-master-book.svg"
import ThreeDReactMasterBook from "../../static/images/commonImg/3d-react-master.png"
import ThreeDTSmasterBook from "../../static/images/commonImg/3d-ts-master.png"
import ThreeDNextReactBook from "../../static/images/commonImg/3d-next-react-book.png"
import Star4 from "../../static/images/commonImg/star4.png"
import Star5 from "../../static/images/commonImg/star5.png"

const BooksInfo = [ 
    {title: "React全部入り：Reactマスター", text: "2024年2月リリース。Reactを『なんとなく』は書ける人が、2日で自信を持って使えるようになる本。JavaScriptの基礎からReactの発展的内容まで、React全範囲をカバー", linkKeyword: "react-master", coverImg: ThreeDReactMasterBook, starImg: Star4, starNum: "24"},
    {title: "React + TS徹底フォーカス：TypeScriptマスター with React", text: "2024年3月リリース。TypeScript + Reactの苦手意識を捨てされる本。『TypeScriptをReactで使う』という点に徹底フォーカスした、他にない実用的な一冊", linkKeyword: "ts-master", coverImg: ThreeDTSmasterBook, starImg: Star5, starNum: "19"},
]

const BooksInfo2 = [ 
    {title: "Next.js Server Actionsでつくるフルスタックアプリ", text: "", linkKeyword: "next-fullstack-server-actions", coverImg: NextServerActionsBook, starImg: Star5, starNum: "19"},
    {title: "はじめてつくるAstroサイト", text: "", linkKeyword: "astrobook", coverImg: AstroBook, starImg: Star5, starNum: "19"},
    {title: "はじめてつくるReactアプリ", text: "", linkKeyword: "reactbook", coverImg: ReactBook, starImg: Star5, starNum: "19"},
    {title: "はじめてつくるReactアプリ with TypeScript", text: "", linkKeyword: "reactTSbook", coverImg: ReactBookTS, starImg: Star5, starNum: "19"},
    {title: "はじめてさわるReact & JavaScript", text: "", linkKeyword: "react-book2", coverImg: ReactBook2, starImg: Star5, starNum: "19"},
    {title: "はじめてさわるReact & JavaScript with TypeScript", text: "", linkKeyword: "react-TS-book2", coverImg: ReactBook2TS, starImg: Star5, starNum: "19"},
    {title: "はじめてつくるNext.jsサイト", text: "", linkKeyword: "nextbook", coverImg: NextBook, starImg: Star5, starNum: "19"},
/*     {title: "はじめてつくるJamstackサイト with ヘッドレスCMS", text: "", linkKeyword: "jamstackbook", coverImg: JamstackBook, starImg: Star5, starNum: "19"}, */
    {title: "はじめてつくるバックエンドサーバー　基礎編", text: "", linkKeyword: "nodebook-intro", coverImg: NodeBookIntro, starImg: Star5, starNum: "19"},
    {title: "はじめてつくるバックエンドサーバー　発展編", text: "", linkKeyword: "nodebook-advanced", coverImg: NodeBookAdvanced, starImg: Star5, starNum: "19"},
    {title: "Next.jsでつくるフルスタックアプリ　前編（バックエンド開発）", text: "", linkKeyword: "next-fullstack-app-1", coverImg: NextbookFull1, starImg: Star5, starNum: "19"},
    {title: "Next.jsでつくるフルスタックアプリ　後編（フロントエンド開発）", text: "", linkKeyword: "next-fullstack-app-2", coverImg: NextbookFull2, starImg: Star5, starNum: "19"},
    {title: "Next.jsでつくるフルスタックアプリ　前編（バックエンド開発）", text: "", linkKeyword: "next-book2-1", coverImg: Nextbook2a, starImg: Star5, starNum: "19"},
    {title: "Next.jsでつくるフルスタックアプリ　後編（フロントエンド開発）", text: "", linkKeyword: "next-book2-2", coverImg: Nextbook2b, starImg: Star5, starNum: "19"},
    {title: "Next.jsでつくるフルスタックアプリ with TypeScript　前編（バックエンド開発）", text: "", linkKeyword: "next-fullstack1-ts", coverImg: Nextbook2aTS, starImg: Star5, starNum: "19"},
    {title: "Next.jsでつくるフルスタックアプリ with TypeScript　後編（フロントエンド開発）", text: "", linkKeyword: "next-fullstack2-ts", coverImg: Nextbook2bTS, starImg: Star5, starNum: "19"},
    {title: "MERNでつくるフルスタックアプリ　前編（バックエンド開発）", text: "", linkKeyword: "mern-book-1", coverImg: Mernbook1, starImg: Star5, starNum: "19"},
    {title: "MERNでつくるフルスタックアプリ　後編（フロントエンド開発）", text: "", linkKeyword: "mern-book-2", coverImg: Mernbook2, starImg: Star5, starNum: "19"},
    {title: "はじめてつくるVueアプリ", text: "", linkKeyword: "vuebook", coverImg: VueBook, starImg: Star5, starNum: "19"},
    {title: "はじめてつくるNuxtサイト", text: "", linkKeyword: "nuxtbook", coverImg: NuxtBook, starImg: Star5, starNum: "19"},
    {title: "はじめてつくるGatsbyサイト", text: "", linkKeyword: "gatsbybook", coverImg: GatsbyBook, starImg: Star5, starNum: "19"},
    {title: "はじめてさわるJavaScript", text: "", linkKeyword: "jsbook", coverImg: JSbook, starImg: Star5, starNum: "19"},
    {title: "プロキシサーバーの作り方", text: "", linkKeyword: "proxy-server", coverImg: ProxyBook, starImg: Star5, starNum: "19"},
]

const NextReactBookSection = () => {
    return (
        <div className="new-flex-container" style={{marginBottom: "30px"}}>
            <div className="flex30">
                <a href="https://www.shoeisha.co.jp/book/detail/9784798184678" rel="noopener noreferrer" target="_blank"><img src={ThreeDNextReactBook} alt="ReactBook" className="book-cover"/></a>
            </div>
            <div className="flex70 book-content-container">
                <p>翔泳社より2024年9月リリース。<br/>本格的なウェブアプリケーションを開発をしながら、ステップバイステップでNext.js／Reactの使い方を紹介する入門書。はじめてNext.jsを利用するビギナー向け</p>
                <a href="https://www.shoeisha.co.jp/book/detail/9784798184678" rel="noopener noreferrer" target="_blank">くわしく見る</a>
            </div>
        </div>
    )
}

const Books = () => {
    return (
        <div className="business-books-root">
            <div className="row">
                <div className="col-12">
                    <h2>著書</h2>
                    <NextReactBookSection/>
                    <div className="new-grid-container fr2">
                        {BooksInfo.map((x, index) => {
                            const { text, linkKeyword, coverImg, starImg, starNum } = x
                            return(
                                <div className="new-flex-container" key={index}>
                                    <div className="flex30">
                                        <a href={`/cu3tfwv45sr9jvei9nm2/?r=${linkKeyword}=business`} rel="noopener noreferrer" target="_blank"><img src={coverImg} alt="ReactBook" className="book-cover"/></a>
                                    </div>
                                    <div className="flex70 book-content-container">
                                        <p>{text}</p>
                                        <div>
                                            {/* <img src={starImg} alt="star" className="star" /> */}
                                            {/* <p> {starNum}個の評価</p> */}
                                        </div>
                                        <a href={`/cu3tfwv45sr9jvei9nm2/?r=${linkKeyword}=business`} rel="noopener noreferrer" target="_blank">くわしく見る</a>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <OtherBooks/>
                </div>
            </div>
        </div>
    )
}

const OtherBooks = () => {
    return (
        <div className="new-grid-container fr6">
            {BooksInfo2.map((x, index) => {
                const { linkKeyword, coverImg } = x
                return(
                    <div className="otherbooks" key={index}>
                        <a href={`/cu3tfwv45sr9jvei9nm2/?r=${linkKeyword}=business`} rel="noopener noreferrer" target="_blank"><img src={coverImg} alt="ReactBook" className="book-cover"/></a>
                    </div>
                )
            })}
        </div>
    )
}

const Business = () => {

    // Access Checker
    useEffect(() => {
        window.location.hostname !== "localhost" && ( window.location.search.includes("ce") || window.location.search.includes("myfb") ) &&
            fetch('https://us-central1-nov182021.cloudfunctions.net/server/access-check', {
                method: 'POST',
                headers: {   'Accept': 'application/json', 'Content-Type': 'application/json'},
                body:  JSON.stringify({ collectionName: "business-page", name: window.location.search, email: "monotein.com/businessへのアクセス" })
            })
            .then(res => res.json())
            .then(data => console.log("Access Checker success", data))
            .catch(err => console.log("Access Checker fail", err))
    }, [])
      
    return  (
        <Layout page="Business">
            <Seo title="About monotein" 
                 description="「待ち時間0秒」のハイパフォーマンスなウェブサイト制作専門。Jamstack、ヘッドレスCMSなどの最新テクノロジーで実現したストレスフリーなウェブ体験で、 SEO、アクセス数、サイト回遊率、CV率を高め、あなたのウェブサイトを成功に導きます。"
            />
            <div className="container business">
                <div className="content">
                    <div className="row">
                        <div className="col-12">
                            <h2><span className="monotein">monoteinについて</span></h2>
                            <div className="img-text-container">
                                <div className="img-container">
                                    <StaticImage src="../../static/images/picMyself/writer-pic-200x200.png" alt="Profile Pic" className="profile-img" placeholder="blurred" />
                                </div>
                                <div className="text-container">
                                    <p>三好アキ（ウェブ・エンジニア）</p>
                                    <p>✤ これまで欧州数ヶ国に住み、海外クライアントの案件を多く手がけてきたため、日本語の情報が少ないテクノロジーやツールの最新情報に精通。最新の知見を活かしながら、ウェブ関連分野の課題解決を行う。</p>

                                    <br/>
                                    <p>✤ 専門用語なしでプログラミングを教えるメソッドに定評があり、1200人以上のビギナーを、最新のフロントエンド開発入門に成功させる。Amazonベストセラー1位を複数回獲得している『<a href="https://www.amazon.co.jp/dp/B094Z1R281" rel="noopener noreferrer" target="_blank" style={{textDecoration: "underline"}}>はじめてつくるReactアプリ with TypeScript</a>』など著書多数（22冊）。</p>
                                    <p>Amazon著者ページ → <a href="https://www.amazon.co.jp/stores/author/B099Z51QF2" rel="noopener noreferrer" target="_blank">amazon.co.jp/stores/author/B099Z51QF2</a></p>
                                    <p>X（Twitter） → <a href="https://twitter.com/monotein_" rel="noopener noreferrer" target="_blank" style={{textDecoration: "underline"}}>twitter.com/monotein_</a></p>
                                    <p>note → <a href="https://note.com/monotein" rel="noopener noreferrer" target="_blank" style={{textDecoration: "underline"}}>note.com/monotein</a></p>  
                                    <a href="/cu3tfwv45sr9jvei9nm2/?r=twitter" rel="noopener noreferrer" target="_blank"><img src={TwitterLogo} alt="instagram" className="sns-logo"/></a>
                                    <a href="/cu3tfwv45sr9jvei9nm2/?r=amazon-writer-central=business" rel="noopener noreferrer" target="_blank"><img src={AmazonLogo} alt="AmazonLogo" className="sns-logo"/></a>
                                    <a href="https://note.com/monotein" rel="noopener noreferrer" target="_blank"><img src={NoteLogo} alt="NotoLogo" className="sns-logo"/></a>
                                    {/* <p>ビギナー向け教本『<a href="/amazon-co-jp-book/?r=mono-busi" rel="noopener noreferrer" target="_blank">はじめてつくるReactアプリ</a>』など6冊を販売中。</p> */}
                                </div>
                            </div>
                        </div> 
                    </div>   
                    <Books />
                    <div className="row">
                        <div className="col-7">
                            <h2>事業紹介</h2>
                            <ul>
                                <li>• 事業名（個人事業）<br/>monotein／モノテイン</li>
                                <li>• 代表者<br/>三好アキ</li>
                                {/* <li>• 事業形態<br/>個人事業</li> */}
                                <li>• 事業内容<br/>- 個人事業主 / 中小事業者向けの高速・軽量なウェブサイト制作
                                            <br/>- 静的サイトジェネレーター（SSG）によるサイト開発
                                            <br/>- ヘッドレスCMS、Jamstackによるサイト開発
                                            <br/>- 英語版サイト制作
                                            <br/>- 既存ウェブサイトのリニューアル
                                            <br/>- SEO、ウェブマーケティング施策
                                            <br/>- ウェブ・アプリケーション開発
                                            <br/>- ビギナー向け教本執筆
                                </li>
                                <li>• URL<br/><Link to="/">https://monotein.com</Link></li>
                                <li>• お問い合わせ<br/><a href="mailto:contact@monotein.com"> contact@monotein.com</a> || <Link to="/contact/?r=business">お問い合わせフォーム</Link>
                                    {/*<br/><a href="/cu3tfwv45sr9jvei9nm2/?r=ig-business=business" rel="noopener noreferrer" target="_blank">インスタグラム</a>  || <a href="/cu3tfwv45sr9jvei9nm2/?r=line-business=contact" rel="noopener noreferrer" target="_blank">LINE</a> */}
                                </li>
                            </ul>  
                        </div> 
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Business
